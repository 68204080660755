<template>
    <PageLayout>
        <template v-slot:default>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>{{ $t('accounting.report.title') }}</h1>
                </div>
            </div>
            <TabsNav :items="breadcrumbs()[1].items" />

            <v-row>
                <v-col>
                    <v-card>
                        <v-card-text>
                            <v-simple-table fixed-header>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            {{ $t('accounting.report.trail_balance.columns.account_name') }}
                                        </th>
                                        <th class="text-left">
                                            {{ $t('accounting.report.trail_balance.columns.opening_balance') }}
                                        </th>
                                        <th class="text-left">
                                            {{ $t('accounting.report.trail_balance.columns.debit_turnover') }}
                                        </th>
                                        <th class="text-left">
                                            {{ $t('accounting.report.trail_balance.columns.credit_turnover') }}
                                        </th>
                                        <th class="text-left">
                                            {{ $t('accounting.report.trail_balance.columns.closing_balance') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(account, key) in report" :key="key">
                                        <td class="text-left">
                                            {{ account.account_name }}
                                        </td>
                                        <td class="text-left">
                                            {{ formatNumber(account.opening_balance) }}
                                        </td>
                                        <td class="text-left">
                                            {{ formatNumber(account.debit_turnover) }}
                                        </td>
                                        <td class="text-left">
                                            {{ formatNumber(account.credit_turnover) }}
                                        </td>
                                        <td class="text-left">
                                            {{ formatNumber(account.closing_balance) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
        <template v-slot:side>
            <Side />
        </template>
    </PageLayout>
</template>
<script>
import PageLayout from "@/components/Leentech/PageLayout.vue";
import Side from "@/components/Warehouse/Side.vue";
import TabsNav from "@/components/Leentech/TabsNav.vue";
import NavMixin from "@/plugins/mixins/Nav";
import WithNumberFormater from "@/plugins/mixins/WithNumberFormater";

export default {
    name: "Sale",

    components: {
        TabsNav,
        Side,
        PageLayout
    },

    mixins: [NavMixin, WithNumberFormater],

    data() {
        return {
            report: null
        }
    },

    mounted() {
        this.init();
    },


    methods: {
        init() {
            this.fetchCashFlow();
        },

        fetchCashFlow () {
            this.$http.get('admin/accounting/report/trial_balance')
                .then(response => {
                    this.report = response.body.data;
                })
                .catch(error => {
                    this.$toastr.error(error.body.message);
                });
        }
    }
}
</script>
