export default {
    methods: {
        formatNumber: (number) => {
            if (typeof number !== 'number') {
                throw new Error('Input must be a number');
            }

            // Разделяем целую и дробную части
            const [integerPart, fractionalPart] = number.toString().split('.');

            // Форматируем только целую часть
            const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

            // Возвращаем результат с учетом дробной части (если она есть)
            return fractionalPart ? `${formattedIntegerPart}.${fractionalPart}` : formattedIntegerPart;
        }
    }
}
